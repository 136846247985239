import { FC, useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import useTrades from "../../../hooks/useTrades";
import PartnerInfo from "../partner";
import { getTradeStatus } from "../../../helpers/getText";
import { FaAngleDown, FaChevronRight } from "react-icons/fa6";
import Calender from "../../commons/calender";
import Modal from "../../ui/modal";
import ModalHeader from "../../ui/modal/modal-header";
import Popover from "../../ui/popover";
import Checkbox from "@mui/material/Checkbox";
import NodataAvailable from "../../../assets/images/NodataAvailable.svg";
const tradeStatusList = [
  {
    label: "Acceptance Pending",
    value: "PENDING",
  },
  {
    label: "Trade Acepted",
    value: "ACCEPTED",
  },
  {
    label: "Arival Confirmed",
    value: "ARL_CONFIRMED",
  },
  {
    label: "Patti Finalized",
    value: "PATTI_FINALIZED",
  },
  {
    label: "Payment Completed",
    value: "PMT_COMPLETED",
  },
  {
    label: "Payment Pending",
    value: "PMT_PENDING",
  },
  {
    label: "Trade Cancel",
    value: "CANCEL",
  },
];
const Trades: FC<{
  status: "OPEN" | "CLOSED";
  searchText: string;
  displayMode: "table" | "list";
  //  openStatus: boolean;
}> = ({ status, searchText, displayMode }) => {
  const navigate = useNavigate();
  const params = useParams();

  var storedStatus = JSON.parse(
    localStorage.getItem("tradeStatusValue") || JSON.stringify([])
  ); // array ki and json ki

  const [isTradeStatus, setIsTradeStatus] = useState(false); //popup values
  const [tradeStatusValue, setTradeStatusValue] =
    useState<string[]>(storedStatus); //filter
  const [selectStatus, setselectStatus] = useState<string[]>(storedStatus); // select cheskodaniki indulo "storedStatus" enduku ichanu antey check chesimdi kuda tabs change appudu chupiyali kadaa thats y.
  const label = { inputProps: { "aria-label": "Checkbox demo" } }; //checkbox kosam

  // this is what i written for dates

  var storedDateChange = localStorage.getItem("dateChange") || "false";

  var storedCalenderDate = JSON.parse(
    localStorage.getItem("calenderDate") ||
      JSON.stringify({
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
      })
  );

  const [isCalender, setIsCalender] = useState(false); //show or hide the calender
  const [dateChange, setDateChange] = useState(storedDateChange === "true"); //manage state when the caender date is changed
  const [calenderDate, setCalenderDate] = useState(
    //calender date for storing the data which we are entering fromDate toDate in calender
    storedCalenderDate
  );

  // tabs switch inapudu open nundi close ki status-filter clear avvali
  useEffect(() => {
    //status filter
    if (status == "CLOSED") {
      setTradeStatusValue([]);
      setselectStatus([]);
    } else if (status == "OPEN") {
      //status filter
      storedStatus =
        localStorage.getItem("tradeStatusValue") || JSON.stringify([]);
      setTradeStatusValue(JSON.parse(storedStatus));
      setselectStatus(JSON.parse(storedStatus));
    }
  }, [status]);

  const { trades } = useTrades({
    status: status,
    fromDate: dateChange ? calenderDate.fromDate : "",
    toDate: dateChange ? calenderDate.toDate : "",
    //openStatus : openStatus,
  });

  if (displayMode === "list") {
    return (
      <>
        <div className="bg-light-primary flex h-10 mt-4">
          <div>
            <button
              className="text-blue-500 flex ml-3 mt-2"
              onClick={() => {
                setIsCalender(true);
              }}
            >
              Date{" "}
              <span>
                <FaAngleDown className="mt-[5px]" />
              </span>
            </button>
          </div>

          <div>
            <button
              onClick={() => {
                setIsTradeStatus(true);
              }}
              className="text-blue-500 grow-[2] basis-0 px-2 flex cursor-pointer ml-[140px] mt-2"
            >
              Status
              <span>
                <FaAngleDown className="mt-[5px] ml-[5px]" />
              </span>
            </button>
          </div>
        </div>

        {/* status pop-over at Open Trades */}
        <div>
          <Popover
            open={isTradeStatus}
            onClose={() => {
              setIsTradeStatus(false);
            }}
            fullWidth={true}
          >
            <div>
              <div>
                {tradeStatusList.map((tradeStatus) => (
                  <div
                    style={{ backgroundColor: "white", width: "250px" }}
                    className="flex items-center px-3 py-1 gap-2 cursor-pointer hv-primary"
                    onClick={() => {
                      //onClick for status
                      const newValue = selectStatus.includes(tradeStatus.value)
                        ? selectStatus.filter(
                            (item) => item !== tradeStatus.value
                          )
                        : [...selectStatus, tradeStatus.value];
                      setselectStatus(newValue);
                    }}
                  >
                    <div>
                      {/* checkbox for status */}
                      <span>
                        {" "}
                        <Checkbox
                          {...label}
                          checked={selectStatus.includes(tradeStatus.value)}
                        />
                      </span>
                      <span className="font-semibold text-sm">
                        {tradeStatus.label}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="flex p-3 gap-2 bg-white border-b"
                style={{ width: "220px" }}
              >
                <button
                  className="rounded-md align-items-center h-[40px]"
                  style={{ backgroundColor: "#d7f3dd", width: "100px" }}
                  onClick={() => {
                    setTradeStatusValue([]);
                    setIsTradeStatus(false);
                    setselectStatus([]); // CLEAR APPUDU CHECK BOX MEEDA TICK POVADANIKI
                    localStorage.setItem(
                      "tradeStatusValue",
                      JSON.stringify([])
                    );
                  }}
                >
                  <span className="color-primary"> Clear All</span>
                </button>

                <button
                  className="bg-primary rounded-md align-items-center h-[40px]"
                  style={{ backgroundColor: "white", width: "100px" }}
                  onClick={() => {
                    setIsTradeStatus(false);
                    setTradeStatusValue(selectStatus);
                    localStorage.setItem(
                      "tradeStatusValue",
                      JSON.stringify(selectStatus)
                    );
                  }}
                >
                  <span className="text-white">Apply</span>
                </button>
              </div>
            </div>
          </Popover>
        </div>

        <div>
          <Modal
            open={isCalender}
            onClose={() => {
              setIsCalender(false);
            }}
            width="30vw"
          >
            <>
              {/* calender Modal */}
              <ModalHeader title={"Date Filter"} content={<></>} />
              <div className="py-3 px-6">
                <Calender
                  mode={"range"}
                  onChange={(data: { fromdate: string; todate: string }) => {
                    if (data.fromdate !== "" && data.todate !== "") {
                      setCalenderDate({
                        ...calenderDate,
                        fromDate: moment(data.fromdate).format("YYYY-MM-DD"),
                        toDate: moment(data.todate).format("YYYY-MM-DD"),
                      });
                      if (status === "OPEN") {
                        localStorage.setItem(
                          "calenderDate",
                          JSON.stringify({
                            fromDate: moment(data.fromdate).format(
                              "YYYY-MM-DD"
                            ),
                            toDate: moment(data.todate).format("YYYY-MM-DD"),
                          })
                        );
                        localStorage.setItem("dateChange", "true");
                      } else {
                        localStorage.setItem(
                          "closedCalenderDate",
                          JSON.stringify({
                            fromDate: moment().format("YYYY-MM-DD"),
                            toDate: moment().format("YYYY-MM-DD"),
                          })
                        );
                        localStorage.setItem("closedDateChange", "true");
                      }
                    } else if (data.fromdate !== "" && data.todate === "") {
                      setCalenderDate({
                        ...calenderDate,
                        fromDate: moment(data.fromdate).format("YYYY-MM-DD"),
                        toDate: moment(data.fromdate).format("YYYY-MM-DD"),
                      });
                      if (status === "OPEN") {
                        localStorage.setItem(
                          "calenderDate",
                          JSON.stringify({
                            fromDate: moment(data.fromdate).format(
                              "YYYY-MM-DD"
                            ),
                            toDate: moment(data.fromdate).format("YYYY-MM-DD"),
                          })
                        );
                        localStorage.setItem("dateChange", "true");
                      } else {
                        localStorage.setItem(
                          "closedCalenderDate",
                          JSON.stringify({
                            fromDate: moment(data.fromdate).format(
                              "YYYY-MM-DD"
                            ),
                            toDate: moment(data.fromdate).format("YYYY-MM-DD"),
                          })
                        );
                        localStorage.setItem("closedDateChange", "true");
                      }
                    }
                    setIsCalender(false); //idhi false cheyakapotey calender open loney untadi
                    setDateChange(true); // filtering data
                  }}
                  onClear={() => {
                    if (status === "OPEN") {
                      localStorage.setItem("dateChange", "false");
                      localStorage.setItem("calenderDate", "");
                    } else {
                      localStorage.setItem("closedDateChange", "false");
                      localStorage.setItem("closedCalenderDate", "");
                    }
                    setCalenderDate({
                      ...calenderDate,
                      fromDate: moment().format("YYYY-MM-DD"),
                      toDate: moment().format("YYYY-MM-DD"),
                    });
                    setDateChange(false);
                    setIsCalender(false);
                  }}
                  initialDateRange={{
                    fromdate: calenderDate.fromDate,
                    todate: calenderDate.toDate,
                  }}
                  maxDate={moment().format("YYYY-MM-DD")}
                  style={{}}
                />
              </div>
            </>
          </Modal>
        </div>
        <div
          className="overflow-auto"
          style={{ height: "calc(100vh - 15.5rem)" }}
        >
          {(searchText !== ""
            ? trades.filter((trade) => {
                return trade.tradeId.toString().includes(searchText);
              }) // status filter
            : tradeStatusValue.length !== 0
            ? trades.filter((trade) => {
                // if(tradeStatusValue.includes('ALL')){
                //   return trades;
                // }
                return tradeStatusValue.includes(trade.tradeStatus);
              })
            : trades
          ).map((trade) => {
            let isActive = (params.tradeId || "") === trade.tradeId.toString();
            return (
              <div
                className={`border-b py-3 px-2 flex ${
                  isActive ? " bg-primary text-white" : ""
                }`}
                onClick={() => {
                  navigate(
                    `/trade-detail/${trade.srcPartyId}/${trade.tradeId}?tab=${status}`
                  );
                }}
              >
                <div className="flex flex-col">
                  <span
                    className={`text-xs font-semibold ${
                      isActive ? "text-white" : "color-blue"
                    }`}
                  >
                    Global ID: {trade.tradeId}
                  </span>
                  <span className="text-sm font-semibold">
                    From : {trade.srcTradeName}
                  </span>
                  <span className="text-xs">
                    {moment(trade.date).format("DD MMM YYYY")}
                  </span>
                </div>
                <div
                  className={`${
                    isActive ? "color-white" : "color-primary"
                  } ml-auto flex items-center font-semibold text-sm`}
                >
                  {/* {trade.tradeStatus + trade.srcTradeStatus} */}
                  {
                    trade.tradeStatus == "PMT_COMPLETED" &&
                    trade.srcTradeStatus == "PMT_COMPLETED"
                      ? "PMT_COMPLETED"
                      : trade.tradeStatus == "PMT_PENDING" ||
                        trade.srcTradeStatus == "PMT_PENDING"
                      ? "PMT_PENDING"
                      : trade.tradeStatus == "PMT_COMPLETED" ||
                        trade.srcTradeStatus == "PMT_COMPLETED"
                      ? "PMT_PENDING"
                      : trade.tradeStatus
                    /* {getTradeStatus(
                trade.tradeStatus,
                0, //trade.paidAmt
                trade.arlTime ? trade?.arlTime : "",
                trade.pattiAmt,
                trade.totalPayable
              )} */
                  }
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  return (
    <div
      className="border overflow-hidden"
      style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
    >
      <div className="flex bg-light-primary text-sm font-medium py-2">
        <div className="text-center px-2" style={{ flexBasis: "4rem" }}>
          #
        </div>
        <div className="grow-[1] basis-0 px-2">Global ID</div>
        <div className="grow-[2] basis-0 px-2 flex">
          Trade ID |{" "}
          <button
            className="text-blue-500 flex ml-1"
            onClick={() => setIsCalender(true)}
          >
            Date{" "}
            <span>
              <FaAngleDown className="mt-[5px]" />
            </span>
          </button>
        </div>
        <div className="grow-[2.5] basis-0 px-2">Source Party</div>
        <div className="grow-[2] basis-0 px-2">From</div>
        <div className="grow-[2.5] basis-0 px-2">Destination Party</div>
        <div className="grow-[2] basis-0 px-2">To</div>
        {status == "OPEN" ? (
          <div className="mr-[130px]">
            <div
              onClick={() => {
                setIsTradeStatus(true);
              }}
              className="text-blue-500 grow-[2] basis-0 px-2 flex cursor-pointer"
            >
              Status
              <span>
                <FaAngleDown className="mt-[5px] ml-[5px]" />
              </span>
            </div>
            <Popover
              open={isTradeStatus}
              onClose={() => {
                setIsTradeStatus(false);
              }}
              // fullWidth={true}
            >
              <div>
                {tradeStatusList.map((tradeStatus) => (
                  <div
                    style={{ backgroundColor: "white", width: "250px" }} //list venaka back ground color
                    className="flex items-center px-3 py-1 gap-2 cursor-pointer hv-primary"
                    onClick={() => {
                      //onClick for status
                      const newValue = selectStatus.includes(tradeStatus.value)
                        ? selectStatus.filter(
                            (item) => item !== tradeStatus.value
                          )
                        : [...selectStatus, tradeStatus.value];
                      setselectStatus(newValue);
                    }}
                  >
                    <div>
                      {/* checkbox for status */}
                      <span>
                        {" "}
                        <Checkbox
                          {...label}
                          checked={selectStatus.includes(tradeStatus.value)}
                        />
                      </span>
                      <span className="font-semibold text-sm">
                        {tradeStatus.label}
                      </span>
                    </div>
                  </div>
                ))}
                <div className="flex p-3 gap-2" style={{ width: "220px" }}>
                  <button
                    className=" align-items-center rounded-md h-[40px]"
                    style={{ backgroundColor: "#d7f3dd", width: "100px" }}
                    onClick={() => {
                      setTradeStatusValue([]);
                      setIsTradeStatus(false);
                      setselectStatus([]); // CLEAR APPUDU CHECK BOX MEEDA TICK POVADANIKI
                      localStorage.setItem(
                        "tradeStatusValue",
                        JSON.stringify([])
                      );
                    }}
                  >
                    <span className="color-primary"> Clear All</span>
                  </button>
                  <button
                    className="bg-primary align-items-center rounded-md h-[40px]"
                    style={{ backgroundColor: "white", width: "100px" }}
                    onClick={() => {
                      setIsTradeStatus(false);
                      setTradeStatusValue(selectStatus);
                      localStorage.setItem(
                        "tradeStatusValue",
                        JSON.stringify(selectStatus)
                      );
                    }}
                  >
                    <span className="text-white">Apply</span>
                  </button>
                </div>
              </div>
            </Popover>
          </div>
        ) : (
          <span className="grow-[2] basis-0 px-2">Status</span>
        )}
      </div>
      {trades.length > 0 ? (
        <div
          className="overflow-auto"
          style={{ height: "calc(100vh - 13rem)" }}
        >
          {(searchText !== ""
            ? trades.filter((trade) => {
                return trade.tradeId.toString().includes(searchText);
              })
            : // status filter
            tradeStatusValue.length !== 0
            ? trades.filter((trade) => {
                // if(tradeStatusValue.includes('ALL')){
                //   return trades;
                // }
                return tradeStatusValue.includes(trade.tradeStatus);
              })
            : trades
          ).map((trade, index) => (
            <div
              key={trade.tradeId}
              className="flex border-b text-sm py-2 cursor-pointer"
              onClick={() => {
                navigate(
                  `/trade-detail/${trade.srcPartyId}/${trade.tradeId}?tab=${status}`
                );
              }}
            >
              <span className="text-center px-2" style={{ flexBasis: "4rem" }}>
                {index + 1}
              </span>
              <span className="grow-[1] basis-0 flex color-blue px-2 text-sm">
                {trade.tradeId}
              </span>
              <div className="grow-[2] basis-0 flex flex-col px-2">
                <span className="text-sm font-semibold">{trade.traderSeq}</span>
                <span className="text-xs">
                  {trade.date != null
                    ? moment(trade.date).format("DD-MMM-YYYY")
                    : ""}
                  {moment(trade.timeStamp).format(" | hh:mm a")}
                </span>
              </div>
              <div className="grow-[2.5] basis-0 px-2">
                <PartnerInfo
                  partyType="From"
                  name={trade.srcName || ""}
                  tradeName={trade.srcTradeName || ""}
                  profilePic=""
                />
              </div>
              <span className="grow-[2] basis-0 px-2">
                {" "}
                {trade.srcAddrLine || trade.srcMarketName || trade.srcDist ? (
                  <span>
                    {trade.srcAddrLine}
                    {trade.srcMarketName}, {trade.srcDist}, {trade.srcState}{" "}
                    {trade.srcPincode}
                  </span>
                ) : (
                  "--"
                )}
              </span>
              <div className="grow-[2.5] basis-0 px-2">
                <PartnerInfo
                  partyType="To"
                  name={trade.destName}
                  tradeName={trade.destTradeName}
                  profilePic=""
                />
              </div>
              <span className="grow-[2] basis-0 px-2">
                {trade.destAddrLine ||
                trade.destMarketName ||
                trade.destCity ? (
                  <span>
                    {trade.destAddrLine}, {trade.destMarketName}{" "}
                    {trade.destDist},{trade.destState} {trade.destPincode}
                  </span>
                ) : (
                  "--"
                )}
              </span>
              <div className="grow-[2] basis-0 flex px-2">
                <span className="grow-[2]">
                  {
                    trade.tradeStatus == "PMT_COMPLETED" &&
                    trade.srcTradeStatus == "PMT_COMPLETED"
                      ? "PMT_COMPLETED"
                      : trade.tradeStatus == "PMT_PENDING" ||
                        trade.srcTradeStatus == "PMT_PENDING"
                      ? "PMT_PENDING"
                      : trade.tradeStatus == "PMT_COMPLETED" ||
                        trade.srcTradeStatus == "PMT_COMPLETED"
                      ? "PMT_PENDING"
                      : trade.tradeStatus
                    /* {getTradeStatus(
                  trade.tradeStatus,
                  0, //trade.paidAmt
                  trade.arlTime ? trade?.arlTime : "",
                  trade.pattiAmt,
                  trade.totalPayable
                )} */
                  }
                  <br />
                  <span className="text-xs">
                    {trade.tradeStatus == "CLOSED"
                      ? trade.updatedOn
                        ? moment(trade.updatedOn).format(
                            "DD-MMM-YYYY | hh:mm a"
                          )
                        : ""
                      : ""}
                  </span>
                </span>

                <FaChevronRight />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center py-8">
          <img src={NodataAvailable} alt="image" />
        </div>
      )}

      <div>
        <Modal
          open={isCalender}
          onClose={() => {
            setIsCalender(false);
          }}
          width="30vw"
        >
          <>
            <ModalHeader title={"Date Filter"} content={<></>} />
            <div className="py-3 px-6">
              <Calender
                mode={"range"}
                onChange={(data: { fromdate: string; todate: string }) => {
                  if (data.fromdate !== "" && data.todate !== "") {
                    setCalenderDate({
                      ...calenderDate,
                      fromDate: moment(data.fromdate).format("YYYY-MM-DD"),
                      toDate: moment(data.todate).format("YYYY-MM-DD"),
                    }); // dates apply ipotunai
                    if (status === "OPEN") {
                      localStorage.setItem(
                        "calenderDate",
                        JSON.stringify({
                          fromDate: moment(data.fromdate).format("YYYY-MM-DD"),
                          toDate: moment(data.todate).format("YYYY-MM-DD"),
                        })
                      ); // calender date ki from date to date set chestuna
                      localStorage.setItem("dateChange", "true"); // date change true avutundi api loki dates veltunai
                    } else {
                      //closed dates all vachestaai
                      localStorage.setItem(
                        "closedCalenderDate",
                        JSON.stringify({
                          fromDate: moment().format("YYYY-MM-DD"),
                          toDate: moment().format("YYYY-MM-DD"),
                        })
                      );
                      localStorage.setItem("closedDateChange", "true");
                    }
                  } else if (data.fromdate !== "" && data.todate === "") {
                    // single date selection
                    setCalenderDate({
                      ...calenderDate,
                      fromDate: moment(data.fromdate).format("YYYY-MM-DD"),
                      toDate: moment(data.fromdate).format("YYYY-MM-DD"),
                    });
                    if (status === "OPEN") {
                      localStorage.setItem(
                        "calenderDate",
                        JSON.stringify({
                          fromDate: moment(data.fromdate).format("YYYY-MM-DD"),
                          toDate: moment(data.fromdate).format("YYYY-MM-DD"),
                        })
                      );
                      localStorage.setItem("dateChange", "true");
                    } else {
                      localStorage.setItem(
                        "closedCalenderDate",
                        JSON.stringify({
                          fromDate: moment(data.fromdate).format("YYYY-MM-DD"),
                          toDate: moment(data.fromdate).format("YYYY-MM-DD"),
                        })
                      );
                      localStorage.setItem("closedDateChange", "true");
                    }
                  }
                  setIsCalender(false); //idhi false cheyakapotey calender open loney untadi
                  setDateChange(true); // filtering data
                }}
                onClear={() => {
                  if (status === "OPEN") {
                    localStorage.setItem("dateChange", "false");
                    localStorage.setItem("calenderDate", "");
                  } else {
                    localStorage.setItem("closedDateChange", "false");
                    localStorage.setItem("closedCalenderDate", "");
                  }
                  setCalenderDate({
                    ...calenderDate,
                    fromDate: moment().format("YYYY-MM-DD"),
                    toDate: moment().format("YYYY-MM-DD"),
                  });
                  setDateChange(false);
                  setIsCalender(false);
                }}
                initialDateRange={{
                  fromdate: calenderDate.fromDate,
                  todate: calenderDate.toDate,
                }}
                maxDate={moment().format("YYYY-MM-DD")}
                style={{}}
              />
            </div>
          </>
        </Modal>
      </div>
    </div>
  );
};
export default Trades;
