import React, { FC, useEffect } from 'react';
import comingsoon from '../../assets/images/coming_soon.gif';
import { FaChevronRight } from "react-icons/fa6";
import EmptyProfile from "../../assets/images/profile.jpg";
import { useNavigate } from "react-router-dom";
import market from "../../assets/images/market.png";
import timer from "../../assets/images/timer-img.png";
import tickmark from "../../assets/images/tickmark.png";
import useAllTraders from "../../hooks/useAllTraders";
import { useDispatch } from "react-redux";
import { updateTraderId } from "../../reducers/tradeSlice";
import { getCurrencyNumberWithSymbol, getMaskedMobileNumber } from '../../helpers/get-currency-number';
import { TTrader } from '../../types';

const AllCustomers: FC<{
  traders: TTrader[];
}> = ({ traders }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { traders} = useAllTraders();

  return (
    <div>
      <div
        style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
        className="ml-3 mr-3 border-gray"
      >

        <div
          className="overflow-x-auto"
          style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
        >
          <div
            className="overflow-y-auto"
            style={{ height: "calc(100vh - 10rem)" }}
          >
            <div className="flex bg-light-primary text-sm font-semibold py-2 sticky top-0 bg-gray-50">
              <span className="grow-[0.2] basis-0 px-2 text-center px-2">#</span>
              <span className="grow-[1.5] basis-0 px-2">Trader</span>
              <span className="grow-[1] basis-0 px-2">Trader Type</span>
              <span className="grow-[1.8] basis-0 px-4">Market</span>
              <span className="grow-[1] basis-0 px-2">
                Pending Balance(₹)
              </span>
              <span className="grow-[1] basis-0 px-3">Trade Limit(₹)</span>
              <span className="grow-[1] basis-0 px-2" >Status</span>
            </div>
            <div className="text-sm">
              <ul>
                {traders.map((trader, index) => (
                  <li
                    onClick={() => {
                      navigate(`/allDetails/${trader.traderId}`);
                      dispatch(
                        updateTraderId({
                          traderId: trader.traderId,
                          tradeStatus: "ALL",
                        })
                      );
                    }}
                    className="flex p-2 cursor-pointer border-b mb-4"
                  >
                    <span className="grow-[0.2] basis-0 text-center font-semibold mt-1">
                      {index + 1}
                    </span>
                    <div className="grow-[1.8] basis-0 flex ml-4">
                      <div>
                        {(trader?.profilePic === "" ||
                          trader?.profilePic === null) && (
                            <img
                              src={EmptyProfile}
                              alt=""
                              className="w-10 h-10 mt-1 mr-2"
                            />
                          )}
                        {trader?.profilePic !== "" &&
                          trader?.profilePic !== null && (
                            <img
                              className="w-10 h-10 rounded-full object-cover mr-2 mt-1"
                              src={trader?.profilePic}
                            />
                          )}
                      </div>
                      <div>
                        <span className="font-semibold">{trader.tradeName}</span>
                        <br></br>
                        <span className="">
                          Trader-
                          <span className="color-blue">{trader.traderId}</span> |{" "}
                          {getMaskedMobileNumber(trader.mobile || "")}
                        </span>
                        <br></br>
                        <span className="">{trader.name}</span>
                      </div>
                    </div>
                    <span className="grow-[1] basis-0 mr-1 font-semibold">
                      {trader.tradeType ?? "-"}
                    </span>
                    <div className="grow-[2.1] basis-0 flex items-center">
                      <div>
                        <img
                          className="w-10 h-10 rounded-full object-cover"
                          src={market}
                        ></img>
                      </div>
                      <div>
                        <span className="font-semibold ml-1">{trader.marketName ?? "-"}</span>
                      </div>
                    </div>

                    {trader.tradeType === "SOURCE" && (
                      <span className="grow-[1.1] basis-0">
                        <div>
                          {trader.amtDetails !== null ? (
                            <span className=" px-2 color-orange text-sm font-semibold">
                              {" "}
                              {/* {(
                                trader.amtDetails.paidReceived -
                                trader.amtDetails.paidAmount
                              ).toFixed(2)} */}
                              {getCurrencyNumberWithSymbol(
                                trader.amtDetails.paidReceived -
                                trader.amtDetails.paidAmount
                                || 0,
                                true
                              )}
                            </span>
                          ) : (
                            <span className=" px-2 color-orange text-sm font-semibold">
                             {getCurrencyNumberWithSymbol(0, true)} 
                            </span>
                          )}

                          <br />
                          <span className="color-black text-sm ml-1.5">
                            To Be Paid
                          </span>
                        </div>
                      </span>
                    )}

                    {trader.tradeType !== "SOURCE" && (
                      <span className="grow-[1.1] basis-0">
                        <div>
                          {trader.amtDetails !== null ? (
                            <span className=" px-2 color-primary text-sm font-semibold">
                              {" "}
                            
                              {getCurrencyNumberWithSymbol(
                                trader.amtDetails.paidReceived -
                                trader.amtDetails.paidAmount
                                || 0,
                                true
                              )}
                            </span>
                          ) : (
                            <span className=" px-2 color-primary text-sm font-semibold">
                             {getCurrencyNumberWithSymbol(0, true)} 
                            </span>
                          )}
                          <br />

                          <span className="color-black text-sm ml-1.5">
                            To Be Received
                          </span>
                        </div>
                      </span>
                    )}
                    <div className="grow-[0.9] basis-0 px-2">
                      <span className="font-semibold text-sm">
                        {getCurrencyNumberWithSymbol(
                          trader.tradeLimit || 0,
                          true
                        )}
                      </span>
                      {trader.amtDetails !== null && (
                        <p className="text-sm">
                          Avl Limit:{" "}
                          <span className="font-semibold">--
                           
                          </span>
                        </p>
                      )}
                    </div>

                    <span className="grow-[1] basis-0 px-2 flex font-semibold text-base">
                      {trader.status}
                    </span>
                    <div className="">
                      {" "}
                      <FaChevronRight />{" "}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default AllCustomers;