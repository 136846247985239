import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, Card, FileUploader } from "../../components/ui";
import useEditTrade from "./useEditTrade";
import DocumentPreview from "../../components/commons/document-preview";
import { FaXmark } from "react-icons/fa6";
import { toast } from "react-toastify";
import moment from "moment";
import { TRootState } from "../../store";
import useAxiosClient from "../../hooks/useAxiosClient";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

const TradeDocs: FC<{}> = ({}) => {
  const params = useParams();
  const { handlePostCall, handlePutCall } = useAxiosClient();
  const navigate = useNavigate();
  const [onEdit, setOnEdit] = useState(false); // for edit button
  const {
    tapalInvoice,
    receipts,
    otherDocuments,
    onUploadImage,
    onRemoveImage,
    tradeDetail,
    removeImageStatus,
    setTapalInvoice,
    setReceipts,
    setOtherDocuments,
    role,
  } = useEditTrade({
    traderId: parseInt(params.traderId || "0"),
    tradeId: parseInt(params.tradeId || "0"),
    mode: "",
  });

  const { adminId } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
  }));
  console.log(tapalInvoice, "tapalInvoice outtt");
  useEffect(() => {
    console.log(tapalInvoice, "tapalInvoice");
    if (
      tapalInvoice.length > 0 ||
      receipts.length > 0 ||
      otherDocuments.length > 0
    ) {
      setTimeout(() => {
        // onCreateTrade();
      }, 1000);
    }
  }, [tapalInvoice, receipts, otherDocuments]);
  useEffect(() => {
    console.log(tapalInvoice, "tapalInvoice");
    if (
      (tapalInvoice.length == 0 ||
        receipts.length == 0 ||
        otherDocuments.length == 0) &&
      removeImageStatus
    ) {
      setTimeout(() => {
        // onCreateTrade();
      }, 1000);
    }
  }, [removeImageStatus]);
  const onCreateTrade = async () => {
    // let formdata = new FormData();
    // formdata.append("traderId", sourcePartyId!.toString());
    let images = tapalInvoice.concat(receipts, otherDocuments);
    for (let img of images) {
      //formdata.append("file", img, img.name.replace(" ", "-"));
    }
    try {
      let imgs: Array<{
        id: number;
        imgFrom: string;
        reqId: number;
        status: number;
        // tradeId: number;
        type: "tapal" | "receipt" | "other";
        url: string;
        addedBy: number;
        typeId: number;
      }> = [];
      if (removeImageStatus) {
        if (tradeDetail?.invoices) {
          for (let image of tradeDetail.invoices) {
            if (image.status == 0) {
              imgs.push({
                ...image,
                // tradeId: tradeDetail.tradeId,
                addedBy: adminId,
                typeId: image.reqId,
              });
            }
          }
        }
        if (tradeDetail?.receipts) {
          for (let image of tradeDetail.receipts) {
            if (image.status == 0) {
              imgs.push({
                ...image,
                // tradeId: tradeDetail.tradeId,
                addedBy: adminId,
                typeId: image.reqId,
              });
            }
          }
        }
        if (tradeDetail?.otherDocumets) {
          for (let image of tradeDetail.otherDocumets) {
            if (image.status == 0) {
              imgs.push({
                ...image,
                // tradeId: tradeDetail.tradeId,
                addedBy: adminId,
                typeId: image.reqId,
              });
            }
          }
        }
      }
      if (images.length > 0) {
        if (tapalInvoice.length > 0) {
          for (let file of tapalInvoice) {
            imgs.push({
              id: 0,
              imgFrom: "TP_TRD",
              reqId: tradeDetail?.tradeId || 0,
              status: 1,
              // tradeId: 0,
              type: "tapal",
              url: file.url,
              addedBy: adminId,
              typeId: 0,
            });
          }
        }
        if (receipts.length > 0) {
          for (let file of receipts) {
            imgs.push({
              id: 0,
              imgFrom: "TP_TRD",
              reqId: tradeDetail?.tradeId || 0,
              status: 1,
              // tradeId: 0,
              type: "receipt",
              url: file.url,
              addedBy: adminId,
              typeId: 0,
            });
          }
        }
        if (otherDocuments.length > 0) {
          for (let file of otherDocuments) {
            imgs.push({
              id: 0,
              imgFrom: "TP_TRD",
              reqId: tradeDetail?.tradeId || 0,
              status: 1,
              // tradeId: 0,
              type: "other",
              url: file.url,
              addedBy: adminId,
              typeId: 0,
            });
          }
        }
      }
      let postBody = {
        creation: {
          destPartyId: tradeDetail?.destPartyId,
          images: imgs,
          srcPartyId: tradeDetail?.srcPartyId,
          srcName: tradeDetail?.srcName,
          date: tradeDetail?.date,
          timeStamp: moment(tradeDetail?.timeStamp).format(
            "YYYY-MM-DDTHH:mm:ss.SSSz"
          ),
          tradeId: tradeDetail?.tradeId ? tradeDetail?.tradeId : 0,
          tradeStatus: tradeDetail?.tradeStatus,
          freightAmt: tradeDetail?.freightAmt,
          // lineItems: [],s
          invAmt: tradeDetail?.invAmt,
          tradeMode: tradeDetail?.tradeMode,
          freightAdv: tradeDetail?.freightAdv,
          comments: tradeDetail?.tradecomments || "UPDATED",
          company: tradeDetail?.company,
          destRMMobile: tradeDetail?.destRmMobile,
          destRmName: tradeDetail?.destRmName,
          srcRMMobile: tradeDetail?.srcRmMobile,
          srcRmName: tradeDetail?.srcRmName,
          srcVehNum: tradeDetail?.srcVehNum,
          cmdityCost:
            tradeDetail?.tradeMode == "ORDER" ? tradeDetail?.cmdityCost : "",
        },
        adminId: adminId,
        tradeId: tradeDetail?.tradeId,
        tradeMode: tradeDetail?.tradeMode,
      };
      try {
        await handlePutCall<any>({
          URL: "admin/trade-platform/trades/metadata",
          apiParams: postBody,
        })
          .then(({ data, status }) => {
            toast.success("Images Uploaded Successfully.");
            navigate(
              `/trade-detail/${tradeDetail?.srcPartyId}/${tradeDetail?.tradeId}?tab=OPEN`
            );
            // window.location.reload();
            // navigate(`/order-view/${data}`);
            // localStorage.setItem("activeTab", "tradeDetails");
            // let id = data != 0 ? (data as number) : 0;
            // dispatch(updateTradeId({ tradeId: id.toString() }));
            setTapalInvoice([]);
            setReceipts([]);
            setOtherDocuments([]);
          })
          .catch((err: any) => {
            toast.error(err.response.data.status.message);
          });
      } catch (ex) {
        toast.error("Create trade failed please try again");
      }
    } catch (ex) {
      toast.error("File upload failed please try again");
    }
  };
  return (
    <>
      {tradeDetail != null && (
        <Card
          titile={
            <div className="flex items-center">
              <span>Trade Document</span>
              {/* when it is not in edit state it show edit */}
              {!onEdit && role == "ADMIN" && (
                <Button
                  variant="outlined"
                  className="ml-auto gap-1"
                  size="small"
                  onClick={() => {
                    setOnEdit(true);
                  }}
                >
                  <FaEdit className="" />
                  Edit
                </Button>
              )}
              {/* when it is edit state it show save */}
              {onEdit && (
                <Button
                  variant="outlined"
                  className="ml-auto gap-1"
                  size="small"
                  onClick={() => {
                    setOnEdit(false);
                    onCreateTrade();
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          }
          className="px-3"
        >
          <h2 className="color-text-primary pb-2 pt-2 text-sm">
            Tapal / Invoice
          </h2>
          <div className="flex gap-2 border-b overflow-auto">
            {/* {editDetail.section ===
                                  "trade_detail_document" &&
                                  editDetail.mode === "edit" && ( */}
            <div className="shrink-0 overflow-hidden p-2">
              {tradeDetail?.tradeStatus !== "CLOSED" && role == "ADMIN" && (
                <FileUploader
                  label=""
                  onChange={(e) => {
                    onUploadImage(
                      "tapal",
                      e.target.files ? e.target.files[0] : null
                    );
                    setOnEdit(true);
                  }}
                />
              )}
            </div>
            {/* )} */}
            {tapalInvoice.length > 0 &&
              tapalInvoice.map((image, i) => (
                <div
                  className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                  style={{
                    width: "33.333%",
                    height: "10rem",
                  }}
                  key={`tapal-doc-${i}`}
                >
                  <a href={image.url} target="_blank">
                    <DocumentPreview url={image.url} docType={image.docType} />
                  </a>
                  {/* {editDetail.section === "trade_detail_document" &&
                  editDetail.mode === "edit" && ( */}
                  {onEdit && (
                    <span
                      className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                      style={{ background: "#ff0000de" }}
                      onClick={() => {
                        onRemoveImage("tapal", i);
                      }}
                    >
                      <FaXmark style={{ color: "white" }} />
                    </span>
                  )}

                  {/* )} */}
                </div>
              ))}
            {tradeDetail?.invoices.length > 0 &&
              tradeDetail.invoices
                .filter((img) => img.status == 1)
                .map((images, i) => (
                  <div
                    className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                    style={{
                      width: "33.333%",
                      height: "10rem",
                    }}
                    key={`tapal-doc-${i}`}
                  >
                    <a href={images.url} target="_blank">
                      <DocumentPreview
                        url={images.url}
                        docType={images.docType}
                      />
                    </a>
                    {onEdit && (
                      <span
                        className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                        style={{ background: "#ff0000de" }}
                        onClick={() => {
                          onRemoveImage("tapal", images.id, true);
                        }}
                      >
                        <FaXmark style={{ color: "white" }} />
                      </span>
                    )}
                  </div>
                ))}
            {tradeDetail.invoices.length <= 0 && tapalInvoice.length <= 0 && (
              <div
                className="border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-center"
                style={{
                  width: "33.333%",
                  height: "10rem",
                }}
              >
                No Tapal/invoice document uploaded
              </div>
            )}
          </div>

          <h2 className="color-text-primary px-4 pb-2 pt-2 text-sm">
            Weighbridge receipt
          </h2>
          <div className="flex px-4 pb-4 gap-2 border-b overflow-auto">
            {/* {editDetail.section ===
                                  "trade_detail_document" &&
                                  editDetail.mode === "edit" && ( */}
            <div className="shrink-0 overflow-hidden p-2">
              {tradeDetail?.tradeStatus !== "CLOSED" && role == "ADMIN" && (
                <FileUploader
                  label=""
                  onChange={(e) => {
                    onUploadImage(
                      "receipt",
                      e.target.files ? e.target.files[0] : null
                    );
                    setOnEdit(true);
                  }}
                />
              )}
            </div>
            {/* )} */}
            {receipts.length > 0 &&
              receipts.map((image, i) => (
                <div
                  className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                  style={{
                    width: "33.333%",
                    height: "10rem",
                  }}
                  key={`tapal-doc-${i}`}
                >
                  <a href={image.url} target="_blank">
                    <DocumentPreview url={image.url} docType={image.docType} />
                  </a>
                  {onEdit && (
                    <span
                      className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                      style={{ background: "#ff0000de" }}
                      onClick={() => {
                        onRemoveImage("receipt", i);
                      }}
                    >
                      <FaXmark style={{ color: "white" }} />
                    </span>
                  )}
                </div>
              ))}
            {tradeDetail.receipts.length > 0 &&
              tradeDetail.receipts
                .filter((img) => img.status == 1)
                .map((image, i) => (
                  <div
                    className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                    style={{
                      width: "33.333%",
                      height: "10rem",
                    }}
                    key={`receipt-doc-${i}`}
                  >
                    <a href={image.url} target="_blank">
                      <DocumentPreview
                        url={image.url}
                        docType={image.docType}
                      />
                    </a>
                    {onEdit && (
                      <span
                        className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                        style={{ background: "#ff0000de" }}
                        onClick={() => {
                          onRemoveImage("receipt", image.id, true);
                        }}
                      >
                        <FaXmark style={{ color: "white" }} />
                      </span>
                    )}
                  </div>
                ))}

            {tradeDetail.receipts.length <= 0 && receipts.length <= 0 && (
              <div
                className="border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-center"
                style={{
                  width: "33.333%",
                  height: "10rem",
                }}
              >
                No Weighbridge receipt uploaded
              </div>
            )}
          </div>

          <h2 className="color-text-primary px-4 pb-2 pt-2 text-sm">
            Other Documents
          </h2>
          <div className="flex px-4 gap-2 border-b overflow-auto pb-4">
            {/* {editDetail.section ===
                                  "trade_detail_document" &&
                                  editDetail.mode === "edit" && ( */}
            <div className="shrink-0 overflow-hidden p-2">
              {tradeDetail?.tradeStatus !== "CLOSED" && role == "ADMIN" && (
                <FileUploader
                  label=""
                  onChange={(e) => {
                    onUploadImage(
                      "other",
                      e.target.files ? e.target.files[0] : null
                    );
                    setOnEdit(true);
                  }}
                />
              )}
            </div>
            {/* )} */}
            {otherDocuments.length > 0 &&
              otherDocuments.map((image, i) => (
                <div
                  className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                  style={{
                    width: "33.333%",
                    height: "10rem",
                  }}
                  key={`tapal-doc-${i}`}
                >
                  <a href={image.url} target="_blank">
                    <DocumentPreview url={image.url} docType={image.docType} />
                  </a>
                  {onEdit && (
                    <span
                      className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                      style={{ background: "#ff0000de" }}
                      onClick={() => {
                        onRemoveImage("other", i);
                      }}
                    >
                      <FaXmark style={{ color: "white" }} />
                    </span>
                  )}
                </div>
              ))}
            {tradeDetail.otherDocumets.length > 0 &&
              tradeDetail.otherDocumets
                .filter((img) => img.status == 1)
                .map((image, i) => (
                  <div
                    className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                    style={{
                      width: "33.333%",
                      height: "10rem",
                    }}
                    key={`receipt-doc-${i}`}
                  >
                    <a href={image.url} target="_blank">
                      <DocumentPreview
                        url={image.url}
                        docType={image.docType}
                      />
                    </a>
                    {onEdit && (
                      <span
                        className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                        style={{ background: "#ff0000de" }}
                        onClick={() => {
                          onRemoveImage("other", image.id, true);
                        }}
                      >
                        <FaXmark style={{ color: "white" }} />
                      </span>
                    )}
                  </div>
                ))}
            {tradeDetail.otherDocumets.length <= 0 &&
              otherDocuments.length <= 0 && (
                <div
                  className="border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-center"
                  style={{
                    width: "33.333%",
                    height: "10rem",
                  }}
                >
                  No Other Documents Uploaded
                </div>
              )}
          </div>
        </Card>
      )}
      {/* <button
        onClick={() => {
          onCreateTrade();
        }}
      >
        save
      </button> */}
    </>
  );
};
export default TradeDocs;
