import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React, { FC, useEffect, useState } from "react";
import Modal from "../../components/ui/modal";
import Borrowers from "./Borrowers";
import GaadiOperators from "./GaadiOperators";
import Traders from "./Traders";
import AddTrader from "./AddTrader";
import AllCustomers from "./AllCustomers";
import Subscribers from "./Subscribers";
import useAllTraders from "../../hooks/useAllTraders";
const Customer: FC<{}> = ({}) => {
  const [visible, setVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("All");

  const { traders, getAllTraders, onRefresh, setOnRefresh, role } =
    useAllTraders();

  useEffect(() => {
    getAllTraders();
  }, [activeTab, onRefresh]);

  return (
    <div>
      <div className="flex items-center justify-between py-2 px-2">
        <div className="flex space-x-4 text-base">
          <button
            className={`cursor-pointer px-4 py-2 rounded-t-lg bg-white-200  ${
              activeTab === "All" ? "border-green-600 border-b-4" : ""
            } text-base`}
            onClick={() => setActiveTab("All")}
          >
            All
          </button>
          <button
            className={`cursor-pointer px-4 py-2 rounded-t-lg bg-white-200  ${
              activeTab === "Subscribers" ? "border-green-600 border-b-4" : ""
            } text-base`}
            onClick={() => setActiveTab("Subscribers")}
          >
            Subscribers
          </button>
          <button
            className={`cursor-pointer px-4 py-2 rounded-t-lg bg-white-200  ${
              activeTab === "Traders" ? "border-green-600 border-b-4" : ""
            } text-base`}
            onClick={() => setActiveTab("Traders")}
          >
            Traders
          </button>
          <button
            className={`cursor-pointer px-4 py-2 rounded-t-lg bg-white-200  ${
              activeTab === "Borrowers" ? "border-green-600 border-b-4" : ""
            } text-base`}
            onClick={() => setActiveTab("Borrowers")}
          >
            Borrowers
          </button>
          <button
            className={`cursor-pointer px-4 py-2 rounded-t-lg bg-white-200  ${
              activeTab === "GAADI Operators"
                ? "border-green-600 border-b-4"
                : ""
            } text-base`}
            onClick={() => setActiveTab("GAADI Operators")}
          >
            GAADI Operators
          </button>
        </div>
        <div className="flex space-x-4 mr-2">
          {/* <button className="flex items-center bg-white border border-gray-300 text-green-700 rounded-lg px-2 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-green-200">
            <FilterAltIcon className="w-4 h-4 mr-2" />
            Filters
          </button> */}
          {role == "ADMIN" && (
            <button
              onClick={() => setVisible(true)}
              className="flex items-center bg-green-600 text-white rounded-lg px-2 py-2 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400"
            >
              <div className="flex items-center justify-center w-6 h-6 bg-yellow-300 rounded-full mr-2">
                <AddCircleOutlineIcon className="w-4 h-4 text-green-600" />
              </div>
              ADD TRADERS
            </button>
          )}
        </div>{" "}
      </div>

      <div className="items-center">
        {activeTab === "All" && (
          <>
            <AllCustomers traders={traders} />
          </>
        )}
      </div>

      <div className="items-center">
        {activeTab === "Subscribers" && (
          <>
            <Subscribers />
          </>
        )}
      </div>

      <div>{activeTab === "Traders" && <Traders traders={traders} />}</div>

      <div className="items-center">
        {activeTab === "Borrowers" && (
          <>
            <Borrowers />
          </>
        )}
      </div>

      <div className="items-center">
        {activeTab === "GAADI Operators" && (
          <>
            <GaadiOperators />
          </>
        )}
      </div>

      <Modal
        open={visible}
        onClose={() => {
          setVisible(false);
        }}
        width="full"
      >
        <AddTrader
          onRefresh={() => {
            setOnRefresh(!onRefresh);
          }}
          onClose={() => setVisible(false)}
        />
      </Modal>
    </div>
  );
};
export default Customer;
