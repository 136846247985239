import moment from "moment";
import pendingIcon from "../assets/images/pending.svg";
import acceptPendingIcon from "../assets/images/pending_state_active.svg";
import penIcon from "../assets/images/pen.svg";
import tradeAcceptedIcon from "../assets/images/accepted_state_active.svg";
import arrivalConfirmedIcon from "../assets/images/arrival_active.svg";
import pattiIcon from "../assets/images/patti_active.svg";
import paymentCompletedIcon from "../assets/images/pay_com_active.svg";
import { getCurrencyNumberWithOneDigit } from "./get-currency-number";
import paymentPending from "../assets/images/pay_pnd_Active.svg";
import paymentCompleted from "../assets/images/pay_com_active.svg";

export function getText(partType) {
  return (
    partType.charAt(0).toUpperCase() + partType.toLowerCase().slice(1)
  ).replace("_", " ");
}
export function getPartnerType(item, trader) {
  let party = item;
  switch (item) {
    case "FARMER":
      if (trader) {
        party = "TRADER";
      } else {
        party = "Farmer";
      }
      break;
    case "BUYER":
      if (trader) {
        party = "TRADER";
      } else {
        party = item;
      }
      break;
    case "TRANSPORTER":
      party = item;
      break;
    case "Seller":
      if (trader) {
        party = "TRADER";
      } else {
        party = "Farmer";
      }
      break;
    case "LABOR":
      party = "COOLIE";
      break;
  }
  return getText(party);
}
export function getUnitVal(qSetting, cIndex) {
  var unit = qSetting[cIndex].qtyUnit;
  if (cIndex != -1) {
    if (qSetting[cIndex].qtyUnit.toLowerCase() == "kgs") {
      unit = "kgs";
    } else if (qSetting[cIndex].qtyUnit.toLowerCase() == "boxes") {
      unit = "Boxes";
    } else if (qSetting[cIndex].qtyUnit.toLowerCase() == "bags") {
      unit = "Bags";
    } else if (qSetting[cIndex].qtyUnit.toLowerCase() == "sacs") {
      unit = "Sacs";
    } else if (qSetting[cIndex].qtyUnit.toLowerCase() == "loads") {
      unit = "loads";
    } else if (qSetting[cIndex].qtyUnit.toLowerCase() == "pieces") {
      unit = "pieces";
    } else if (qSetting[cIndex].qtyUnit.toLowerCase() == "crates") {
      unit = "crates";
    }
  }
  return unit;
}

export function getQuantityUnit(qSetting, cIndex) {
  var unit = qSetting[cIndex].qtyUnit;
  if (cIndex != -1) {
    if (qSetting[cIndex].qtyUnit.toLowerCase() == "kgs") {
      unit = "kgs";
    } else if (qSetting[cIndex].qtyUnit.toLowerCase() == "boxes") {
      unit = "boxes";
    } else if (qSetting[cIndex].qtyUnit.toLowerCase() == "bags") {
      unit = "bags";
    } else if (qSetting[cIndex].qtyUnit.toLowerCase() == "sacs") {
      unit = "sacs";
    } else if (qSetting[cIndex].qtyUnit.toLowerCase() == "loads") {
      unit = "Loads";
    } else if (qSetting[cIndex].qtyUnit.toLowerCase() == "pieces") {
      unit = "pieces";
    } else if (qSetting[cIndex].qtyUnit.toLowerCase() == "crates") {
      unit = "crates";
    }
  }
  return unit;
}
export function colorAdjustBg(color, amount) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
}
export function getCropUnit(unit, qty) {
  const unitTypes = {
    CRATES: "C",
    BOXES: "Bx",
    BAGS: "Bg",
    SACS: "S",
    LOADS: "L",
    PIECES: "P",
  };
  const abbreviation = unitTypes[unit.toUpperCase()] || "";
  return qty ? qty.toFixed(1) + abbreviation : "";
}
export function formatInvLedger(array) {
  const items = array ? array : [];
  const formattedItems = items
    .map((item) => getCropUnit(item.unit, item.qty))
    .filter((cropUnit) => cropUnit !== "");
  return formattedItems.join(" | ");
}
export function qtyValues(qty, qtyUnit, weight, wastage, rateType) {
  const getCropUnit = (unit) => {
    var unitType = "";
    switch (unit.toLowerCase()) {
      case "crates":
        unitType = " C";
        break;
      case "boxes":
        unitType = " BX";
        break;
      case "bags":
        unitType = " BG";
        break;
      case "sacs":
        unitType = " S";
        break;
      case "loads":
        unitType = " LDS";
        break;
      case "pieces":
        unitType = " PCS";
        break;
      case "kgs":
        unitType = " KGS";
        break;
    }
    return unitType;
  };
  return (
    <p className="crop_name text-left">
      {(qty == 0 ? "" : getCurrencyNumberWithOneDigit(qty)) +
        (qtyUnit.toLowerCase() == "loads" ||
        qtyUnit.toLowerCase() == "pieces" ||
        qtyUnit.toLowerCase() == "kgs"
          ? ""
          : getCropUnit(qtyUnit))}
      {qty == 0 || qty == null
        ? ""
        : weight == 0 || weight == null
        ? ""
        : " | "}
      {getCurrencyNumberWithOneDigit(weight) +
        (qtyUnit.toLowerCase() == "loads" ||
        qtyUnit.toLowerCase() == "pieces" ||
        qtyUnit.toLowerCase() == "kgs"
          ? getCropUnit(qtyUnit)
          : weight == 0 || weight == null
          ? ""
          : " KGS")}
    </p>
  );
}
export function getTradeStatus(
  status,
  paidAmt,
  arlTime,
  pattiAmt,
  totalPayable
) {
  let tradeStatus = status;
  if (status == "PENDING") {
    tradeStatus = "Approval Pending";
  }
  if (status == "ACCEPTED") {
    tradeStatus = "Trade Accepted";
  }
  if (
    status == "ARL_CONFIRMED" ||
    (arlTime != "" && pattiAmt == 0 && status != "PMT_PENDING")
  ) {
    tradeStatus = "Arrival Confirmed";
  }
  if (status == "PATTI_FINALIZED") {
    tradeStatus = "Patti Finalized";
    if (status == "PMT_COMPLETED" || paidAmt == totalPayable) {
      tradeStatus = "Payment Completed";
    } else if (paidAmt < totalPayable) {
      tradeStatus = "Payment Pending";
    } else {
      tradeStatus = "Patti Finalized";
    }
  }
  if (
    (status == "PMT_PENDING" || status == "PAYMENT") &&
    paidAmt > 0 &&
    totalPayable != 0
  ) {
    tradeStatus = "Payment Pending";
  }
  if (status == "PMT_COMPLETED") {
    tradeStatus = "Payment Completed";
  }
  if (status == "CANCEL") {
    tradeStatus = "Cancelled";
  }
  if (status == "CLOSED") {
    tradeStatus = "Trade Closed";
  }
  if (status == "REJECTED") {
    tradeStatus = "REJECTED";
  }
  return tradeStatus;
}
export const getTradeStatusDisplayTime = ({
  status = "",
  tradeCreateTime = "",
  tradeAcceptedTime = "",
  arrivalTime = "",
  paidAmt = 0,
  totalPayable = 0,
  tradeClosedTime = "",
}) => {
  if (status === "PENDING") {
    return {
      lable: "Trade Created on ",
      time: moment(tradeCreateTime).format("DD-MMM-YYYY hh:mm a"),
      icon: acceptPendingIcon,
      icon2: penIcon,
    };
  } else if (status === "ACCEPTED") {
    return {
      lable: tradeAcceptedTime ? "Accepted on " : "",
      time: tradeAcceptedTime
        ? moment(tradeAcceptedTime).format("DD-MMM-YYYY")
        : "",
      icon: tradeAcceptedIcon,
      icon2: "",
    };
  } else if (status === "ARL_CONFIRMED") {
    return {
      lable: "Arrived on ",
      time: moment(arrivalTime).format("DD-MMM-YYYY hh:mm a"),
      icon: arrivalConfirmedIcon,
      icon2: "",
    };
  } else if (status === "PATTI_FINALIZED") {
    //tradeStatus = "Patti Finalized";
    // if (paidAmt >= totalPayable) {
    //   // tradeStatus = "Payment Completed";
    //   return {
    //     lable: "",
    //     time: "",
    //     icon: paymentCompletedIcon,
    //     icon2: "",
    //   };
    // } else if (paidAmt < totalPayable) {
    //   //tradeStatus = "Payment Pending";
    //   return {
    //     lable: "",
    //     time: "",
    //     icon: tradeAcceptedIcon,
    //     icon2: penIcon,
    //   };
    // } else {
    // tradeStatus = "Patti Finalized";
    return {
      lable: "",
      time: "",
      icon: pattiIcon,
      icon2: "",
    };
    // }
  } else if (status === "PMT_PENDING") {
    return {
      lable: "",
      time: "",
      icon: paymentPending,
      icon2: penIcon,
    };
  } else if (status === "PMT_COMPLETED") {
    return {
      lable: "",
      time: "",
      icon: paymentCompleted,
      icon2: "",
    };
  } else if (status === "CLOSED") {
    return {
      lable: tradeClosedTime ? "Closed on " : "",
      time: tradeClosedTime
        ? moment(tradeClosedTime).format("DD-MMM-YYYY | hh:mm a")
        : "",
      icon: "",
      icon2: "",
    };
  } else {
    return {
      lable: "",
      time: "",
      icon: "",
      icon2: "",
    };
  }
};
export default {
  getText,
  getPartnerType,
  getUnitVal,
  getQuantityUnit,
  colorAdjustBg,
  formatInvLedger,
  qtyValues,
};
